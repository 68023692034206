<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/notification_plugin.svg"
          alt="notification plugin logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Notification Plugin
      </template>
      <template #subheader>
        The Notification Plugin allows sending notifications externally for different events.
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <v-row>
          <Notification
            v-for="(notification, i) in notifications"
            :key="'notification-' + notification.key"
            :notification="notification"
            :events="events"
            :methods="methods"
            :segments="segments"
            @update="(v, p, s) => handleNotificationUpdate(i, v, p, s)"
            @delete="deleteId = i; deleteDialog = true;"
          />
        </v-row>
      </validation-observer>

      <v-divider
        v-if="notifications.length"
        class="mt-10"
      />

      <v-btn
        class="custom-button custom-button--blue mt-10 px-13"
        height="34px"
        depressed
        :block="$vuetify.breakpoint.xsOnly"
        @click="addNotification"
      >
        <v-icon color="#fff" size="16" class="mr-0">
          mdi-plus
        </v-icon>
        Add Notification
      </v-btn>

      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />

    <v-dialog v-model="deleteDialog" max-width="360px" @click:outside="deleteId = null">
      <DeleteConfirmationDialog
        v-if="deleteDialog"
        header="Delete Notification"
        subheader="Are you sure you want to delete this notification? Changes won't be saved until you click the button at the bottom of the page."
        @dismiss="
          deleteDialog = false;
          deleteId = null;
        "
        @delete="deleteNotification"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import Notification from "./Notification/Notification"
import { v4 as uuidv4 } from "uuid";

export default {
  name: "AddPluginNotification",
  metaInfo: {
    title: 'Install Notification Plugin'
  },
  components: {
    DeactivateConfirmDialog,
    DeleteConfirmationDialog,
    Notification,
    PageHeader,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    deleteId: null,
    deleteDialog: false,
    plugin: null,
    events: [],
    methods: [],
    notifications: [],
    segments: [],
  }),
  async created() {
    await this.loadNotificationValues();
    await this.loadSegments();

    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadNotificationValues() {
      const eventResp = await this.$rest.plugins.notification.getEvents();
      if (eventResp.data) {
        this.events = eventResp.data.data;
      }
      const methodResp = await this.$rest.plugins.notification.getMethods();
      if (methodResp.data) {
        this.methods = methodResp.data.data;
      }
    },
    async loadSegments() {
      const response = await this.$rest.segment.get_collection({
        ignorePagination: true,
        sort: ['name:asc'],
      });

      if (!response.data) {
        return;
      }

      this.segments = response.data.items.map(item => ({
        id: item.id,
        name: item.isArchived ? item.name + ' [Archived]': item.name,
      }));
    },
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      this.plugin = {...response.data};
      this.notifications = this.plugin?.pluginConfiguration?.notifications;
    },
    addNotification() {
      this.notifications.push({
        key: uuidv4(),
        event: this.events[0]?.key ?? null,
        notificationMethod: this.methods[0]?.key ?? null,
        eventSettings: {},
        methodSettings: {},
      });
    },
    handleNotificationUpdate(index, newValue, property, subProperty) {
      if (subProperty) {
        this.$set(this.notifications[index][property], subProperty, newValue);
        return;
      }

      this.$set(this.notifications[index], property, newValue);
    },
    deleteNotification() {
      this.notifications.splice(this.deleteId, 1);
      this.deleteDialog = false;
      this.deleteId = null;
    },
    trigger() {
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        notifications: this.notifications,
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        notifications: this.notifications,
      };
      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        isActive: true,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }

      const selected_account = this.$store.getters["user/account"];
      this.$store.commit("user/update_selected_account", {
        ...selected_account,
        activePlugins: [...selected_account.activePlugins, response.data],
      });

      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
  },
}
</script>

<style scoped>

</style>
