<template>
  <v-col
    sm="12"
    md="6"
  >
    <div
      class="notification-container"
    >
      <CustomDropdown
        :value="notification.event"
        header="Event Type"
        :required="true"
        :items="events"
        item-text="name"
        item-value="key"
        @change="handleEventChange"
      />

      <CustomDropdown
        v-if="canFilterBySegments"
        :value="notification.eventSettings.segmentIds"
        header="Only Notify for Segments"
        short
        multiple
        autocomplete
        placeholder="Notify about all Segments"
        hint="Choose to only notify about the event if it is related to the selected segment(s)."
        :items="segments"
        item-text="name"
        item-value="id"
        @change="val => updateEventSettings('segmentIds', val)"
      />

      <v-divider class="my-5" />

      <CustomDropdown
        :value="notification.notificationMethod"
        header="Notification Method Type"
        :readonly="methods.length === 1"
        :required="true"
        :items="methods"
        item-text="name"
        item-value="key"
        @change="handleMethodChange"
      />

      <CustomTextInput
        v-if="notification.notificationMethod === 'email'"
        :value="notification.methodSettings.recipients"
        class="mb-2"
        header="Email Recipients"
        required
        hint="This is a comma-separated list of email addresses that should receive the notification email."
        @input="val => updateMethodSettings('recipients', val)"
      />

      <div>
        <v-btn
          class="custom-button custom-button--red px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="$emit('delete')"
        >
          Delete
        </v-btn>
      </div>
    </div>
  </v-col>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "Notification",
  components: {
    CustomDropdown,
    CustomTextInput,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    methods: {
      type: Array,
      required: true,
    },
    segments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    canFilterBySegments() {
      return ['contact_added_to_segment', 'contact_updated'].includes(this.notification.event);
    },
  },
  methods: {
    updateEventSettings(property, value) {
      this.$emit('update', value, 'eventSettings', property);
    },
    updateMethodSettings(property, value) {
      this.$emit('update', value, 'methodSettings', property);
    },
    handleEventChange(event) {
      this.$emit('update', event, 'event');
      this.$emit('update', {}, 'eventSettings');
    },
    handleMethodChange(method) {
      this.$emit('update', method, 'notificationMethod');
      this.$emit('update', {}, 'methodSettings');
    },
  }
}
</script>

<style scoped lang="scss">
.notification-container {
  border: 1px solid #ccc;
  padding: 20px;
}
</style>